<template>
    <div class="card">
      <div class="card-body">
        <a :href="getStringInvoiceListUrl()" target="_blank" class="mb-4">Click here to view and perform further actions on stripe</a>
        <b-card class="mt-2">
          <form action="#">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label>Filter by status</label>
                  <select class="form-control" @change="fetchInvoices" v-model="status">
                    <option value="">All</option>
                    <option value="open">pending / open</option>
                    <option value="paid">paid</option>
                    <option value="void">cancelled / void</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Filter by purpose for invoice</label>
                  <select class="form-control" @change="fetchInvoices" v-model="purpose">
                    <option value="">All</option>
                    <option value="extra">Extra</option>
                    <option value="rentals">Car rentals</option>
                    <option value="providers">Providers</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label > Filter by driver </label>
                  <div class="input-group input-group-merge">
                    <template v-if="driver">
                      <input type="text" class="form-control" placeholder="Enter value here" v-model=" driver['name'] + ' ' + driver['other_names'] " disabled/>
                    </template>
                    <template v-else>
                      <input type="text" class="form-control" placeholder="Enter value here" disabled/>
                    </template>

                    <template v-if="driver">
                      <div class="input-group-append" @click="clearSelectedDriver">
                        <div class="input-group-text bg-danger" style="cursor: pointer">
                          <span class="fa fa-trash text-white"></span>
                        </div>
                      </div>
                    </template>
                   <template v-else>
                     <div class="input-group-append" @click="$bvModal.show('drivers')">
                       <div class="input-group-text bg-info" style="cursor: pointer">
                         <span class="fa fa-car text-white"></span>
                       </div>
                     </div>
                   </template>


                  </div>
                </div>
              </div>
            </div>
          </form>
        </b-card>
        <b-card class="mt-2">
          <div class="row mb-2">
            <div class="col-sm-6 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Display&nbsp;
                  <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                  ></b-form-select
                  >&nbsp;Drivers
                </label>
              </div>
            </div>

            <!-- End search -->
            <!-- Search -->
            <div class="col-sm-6 col-md-6">
              <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <b-table
              table-class="table table-centered w-100"
              thead-tr-class="bg-light"
              :items="items"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
          >

            <template v-slot:cell(name)="data">
              <a href="javascript: void(0);" class="text-dark">
                <b-avatar size="md" variant="light" class="text-dark"
                ><span class="">{{ data.item['user_name'] | initials }}</span></b-avatar
                >
                <span class="ml-2">{{ data.item['user_name'] }} </span>
              </a>
            </template>


            <template v-slot:cell(amountToPay)="data">
              {{data.item['amountToPay']}}
            </template>

            <template v-slot:cell(status)="data">
              <template v-if="data.item['status'] === 'paid'">
                <span class="text-success text-bold">{{data.item['status']}}</span>
              </template>
              <template v-else-if="data.item['status'] === 'void'">
                <span class="text-muted text-bold">{{data.item['status']}}</span>
              </template>
              <template v-else-if="data.item['status'] === 'open'">
                <span class="text-info text-bold">{{data.item['status']}}</span>
              </template>
              <template v-else>
                <span class="text-bold">{{data.item['status']}}</span>
              </template>

            </template>

            <template v-slot:cell(action)="data">
              <template v-if="data.item['status'] === 'open'">
              <a href="#" class="mr-2 text-danger" @click.prevent="resendInvoice(data.item['invoice_id'],data.item['user_name'])">Resend invoice</a>
              </template>
              <a :href=" getStripBaseInvoiceUrl() + data.item['invoice_id']" target="_blank">View on stripe</a>
            </template>

<!--            <template v-slot:cell(items)="data">-->
<!--              <ul>-->
<!--                <li v-for="item in data.item['items']"> {{item['display']}} </li>-->
<!--              </ul>-->
<!--            </template>-->


          </b-table>
          <div class="row">
            <div class="col">
              <div
                  class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded">
                  <!-- pagination -->
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </b-card>
        <shared-drivers @on-drivers-selected="onDriverSelected" ></shared-drivers>
      </div>
    </div>
</template>

<script>
import SharedDrivers from '../drivers/share-driver-list'
import {invoiceService} from "../../../../apis/invoice.service";
import {confirm} from "../../../../utils/functions";

export default {
  name: "list",
  components: {
    SharedDrivers
  },
  data(){
    return {
      status: '',
      purpose: '',
      driver: null,
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "createdAt",
      sortDesc: false,
      invoiceId: null,
      fields: [
        {
          label: "Generated for",
          key: "name",
          sortable: true,
        },
        {
          label: "Date generated",
          key: "createdAt",
          sortable: true,
        },
        {
          label: "Due date",
          key: "dueDate",
          sortable: true,
        },
        {
          label: "Status",
          key: "status",
          sortable: true,
        },
        {
          label: "Invoice Amount",
          key: "amountToPay",
          sortable: true,
        },
        "action",
        // {
        //   label: "Items",
        //   key: "items",
        //   sortable: false,
        // },
      ],
    }
  },
  methods: {

    getStringInvoiceListUrl(){
      return process.env.VUE_APP_STRIPE_INVOICE_LIST
    },

    getStripBaseInvoiceUrl(){
      return process.env.VUE_APP_STRIPE_INVOICE_BASE_URL
    },

    fetchInvoices(mode = 'byFilter'){
      const payload = {
        status: this.status,
        purpose: this.purpose,
        driverUserId: this.driver ? this.driver['id'] : '',
        'mode': mode, //byId, byFilter
        invoiceId: this.invoiceId
      }

      this.$store.dispatch('showLoader')
      invoiceService.fetchInvoices(payload).then((data) => {
        this.$store.dispatch('hideLoader')

        if (!data.status){
          this.$store.dispatch('error', {message: data.message, showSwal: true})
          return;
        }

        // console.log('invoices: ', data.extra)
        this.items = data.extra;

      })

    },


  clearSelectedDriver(){
    this.driver = null;
    this.fetchInvoices();
  },

    resendInvoice(invoiceId, name){

      confirm('Resend invoice to ' + name, () => {
        this.$store.dispatch('showLoader')
        const payload = {
          "invoiceId" : invoiceId
        }
        invoiceService.resendInvoice(payload).then((data) => {
          this.$store.dispatch('hideLoader')

          if (!data.status){
            this.$store.dispatch('error', {message: data.message, showSwal: true})
            return;
          }

          this.$store.dispatch('success', {message: data.message, showSwal: true})

        })
      })

    },

    onDriverSelected(drivers){
      console.log('selected drivers', drivers)
      if(drivers.length === 1){
        this.driver =  drivers[0];
      }else if(drivers.length < 1) {
        this.$store.dispatch('error', { message: "Please select a driver", showSwal: true})
      }else{
        this.$store.dispatch('error', { message: "Please select only one driver", showSwal: true})
      }

      this.fetchInvoices();
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  computed: {

    /**
     * Total no. of records
     */
    rows() {
      return this.items.length;
    },
  },
  created() {
    this.$store.dispatch('setPageTitle', 'Invoices')
    const invoiceId = this.$route.params['id']
    if(invoiceId){
      this.invoiceId = invoiceId;
      this.fetchInvoices('byId');
      return;
    }
    this.fetchInvoices();
  }
}
</script>

<style scoped>

</style>